<template>
  <div>
    <StateRenderer v-bind="rents">
      <b-alert
        v-if="rents.data.length === 0"
        show
        variant="secondary"
        class="text-center"
      >
        Nenhum aluguel realizado neste período
      </b-alert>
      <template v-else>
        <h4>
          <b> Total: {{ calculateTotal | formatCurrency }} </b>
        </h4>
        <b-table
          ref="rentsList"
          :fields="fields"
          :items="rents.data"
          striped
          hover
          :busy="rents.loading"
          responsive
        >
          <template #table-colgroup="scope">
            <col v-for="field in scope.fields" :key="field.key" />
          </template>
          <template #cell(createdAt)="{ item }">
            <span class="d-block">
              {{ getDate(item.createdAt) }}
            </span>
          </template>
          <template #cell(startsAt)="{ item }">
            <span class="d-block">
              {{ item.createdAt | formatHours }}
            </span>
          </template>
          <template #cell(endsAt)="{ item }">
            <span class="d-block">
              {{ calculateEndDate(item) | formatHours }}
            </span>
          </template>
          <template #cell(extraTime)="{ item }">
            <span class="d-block">
              {{ getExtraTime(item) }}
            </span>
          </template>
          <template #cell(pausedTime)="{ item }">
            <span class="d-block">
              {{ getPausedTime(item) }}
            </span>
          </template>
          <template #cell(exceededTime)="{ item }">
            <span class="d-block">
              {{ getExceededTime(item) }}
            </span>
          </template>
          <template #cell(rentTime)="{ item }">
            <span class="d-block">
              {{ getRentTime(item) }}
            </span>
          </template>
          <template #cell(timeCharged)="{ item }">
            <span class="d-block">
              {{ getRentTime(item) - getExceededTime(item) }}
            </span>
          </template>
          <template #cell(extraTimeReason)="{ item }">
            <span class="d-block">
              {{ getExtraTimeReason(item) }}
            </span>
          </template>
          <template #cell(products)="{ item }">
            <span class="d-block">
              {{ item.products.length }}
            </span>
          </template>
          <template #cell(cash)="{ item }">
            <span class="d-block">
              {{ calculateReceivables(item, 'cash') | formatCurrency }}
            </span>
          </template>
          <template #cell(debitCard)="{ item }">
            <span class="d-block">
              {{ calculateReceivables(item, 'debitCard') | formatCurrency }}
            </span>
          </template>
          <template #cell(creditCard)="{ item }">
            <span class="d-block">
              {{ calculateReceivables(item, 'creditCard') | formatCurrency }}
            </span>
          </template>
          <template #cell(pix)="{ item }">
            <span class="d-block">
              {{ calculateReceivables(item, 'pix') | formatCurrency }}
            </span>
          </template>
          <template #cell(status)="{ item }">
            <Status :value="getStatus(item)" />
          </template>
        </b-table>
      </template>
    </StateRenderer>
  </div>
</template>

<script>
import { RENT_STATUS } from '@/constants'
import { reduce, pipe, filter } from 'lodash/fp'
import { formatCurrency } from '@/helpers/numbers'
import { formatDate, formatDateTime, formatHours } from '@/helpers/date'
import { format, addMinutes, differenceInMinutes } from 'date-fns'
import { calculatePausedTime, calculateExtraTime } from '@/helpers/rents'
import StateRenderer from '@/components/StateRenderer'
import ptBRLocale from 'date-fns/locale/pt-BR'

import Status from '@/components/Status'

export default {
  name: 'RentsReportList',
  filters: {
    formatCurrency,
    formatDate,
    formatHours,
    formatDateTime
  },
  components: {
    Status,
    StateRenderer
  },
  props: {
    rents: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      filter: {
        storeId: null,
        date: null
      }
    }
  },
  computed: {
    fields: () => [
      {
        key: 'createdAt',
        label: 'Data'
      },
      {
        key: 'toy.name',
        label: 'Brinquedo'
      },
      {
        key: 'customer.name',
        label: 'Cliente'
      },
      {
        key: 'startsAt',
        label: 'Início'
      },
      {
        key: 'endsAt',
        label: 'Retorno'
      },
      {
        key: 'period.time',
        label: 'Período'
      },
      {
        key: 'extraTime',
        label: 'Adicional'
      },
      {
        key: 'pausedTime',
        label: 'Pausado'
      },
      {
        key: 'exceededTime',
        label: 'Excedido'
      },
      {
        key: 'rentTime',
        label: 'Permanência'
      },
      {
        key: 'timeCharged',
        label: 'Cobrado'
      },
      {
        key: 'extraTimeReason',
        label: 'Motivo tempo adicional'
      },
      {
        key: 'cash',
        label: 'Total Dinheiro'
      },
      {
        key: 'creditCard',
        label: 'Total Crédito'
      },
      {
        key: 'debitCard',
        label: 'Total Débito'
      },
      {
        key: 'pix',
        label: 'Total Pix'
      },

      {
        key: 'status',
        label: 'Status'
      }
    ],
    calculateTotal() {
      return reduce(
        (result, current) => {
          const total = reduce(
            (acc, cur) => (acc += cur.amount),
            0,
            current.payments
          )

          return (result += total)
        },
        0,
        this.rents.data
      )
    }
  },
  methods: {
    getDate(date) {
      return format(new Date(date), 'dd/MM/yyyy', {
        locale: ptBRLocale
      })
    },
    calculateEndDate(rent) {
      const rentTime = this.getRentTime(rent)
      if (rentTime <= rent.period.time) {
        const startDate = new Date(rent.createdAt)
        return addMinutes(startDate, rent.period.time)
      }

      const startDate = new Date(rent.createdAt)
      return addMinutes(startDate, rentTime)
    },
    calculateReceivables(rent, type) {
      return pipe(
        filter((item) => item.type === type),
        reduce((result, current) => {
          return (result += current.amount)
        }, 0)
      )(rent.payments)
    },
    getPausedTime(rent) {
      return calculatePausedTime(rent)
    },
    getExtraTime(rent) {
      return calculateExtraTime(rent)
    },
    getExtraTimeReason(rent) {
      return reduce(
        (result, current) =>
          result + (result === '' ? '' : ' / ') + current.reason,
        '',
        rent.extraTime
      )
    },
    getRentTime(rent) {
      if (!rent.endedAt || (rent.pausedAt && !rent.endedAt)) {
        return 0
      }

      const { createdAt, endedAt } = rent
      const startDate = new Date(createdAt)
      const endDate = new Date(endedAt)
      return differenceInMinutes(endDate, startDate)
    },
    getExceededTime(rent) {
      const rentTime = this.getRentTime(rent)
      if (rentTime <= rent.period.time) {
        return 0
      }

      const extraTime = calculateExtraTime(rent)
      const result = rentTime - rent.period.time - extraTime
      return result >= 0 ? result : 0
    },
    getStatus(rent) {
      if (rent.canceledAt) {
        return RENT_STATUS.CANCELLED.value
      }

      if (rent.endedAt) {
        return RENT_STATUS.ENDED.value
      }

      if (rent.pausedAt) {
        return RENT_STATUS.PAUSED.value
      }

      return RENT_STATUS.ACTIVE.value
    }
  }
}
</script>
